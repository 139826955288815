import React, { useState, Suspense, useRef, useEffect, useMemo, useLayoutEffect } from "react"
import { Canvas, extend, useFrame, useLoader, useThree } from "@react-three/fiber"
import { Environment, Html, useProgress, useAnimations } from "@react-three/drei"
import { useGLTF } from "../src/hooks/useGLTF"
import { EffectComposer, SSAO, Bloom, Noise, ToneMapping, DepthOfField } from "@react-three/postprocessing"
import { useTranslation } from "react-i18next"
import { useReseller } from "../src/context/resellerContext"
import LanguageOverlay from "../src/components/languageOverlay"
import PrivacyOverlay from "../src/components/privacyOverlay"
import ResellerBar from "../src/components/resellerBar"
import { OrbitControls } from "../src/OrbitControls"
import { LoopOnce, MathUtils } from "three"
import { useSpring } from "framer-motion"
import { isMobile } from "react-device-detect"
import "../src/services/i18n"
import i18n from "i18next"
import { Vector3 } from "three"
import lerp from "@14islands/lerp"
import ModelConfig from "../src/modelConfig.json"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

extend({ OrbitControls })

function LoadedModel(props) {
  const product = "s1"
  const [color, setColor] = useState(0)
  const config = ModelConfig[product]
  const controls = useRef()
  const [animating, setAnimating] = useState(false)
  const [accessory, setAccessory] = useState(false)
  const group = useRef()
  const vec = new Vector3()
  const speed = 0.058
  const {
    camera,
    gl: { domElement },
  } = useThree()
  useFrame(({ clock }, delta) => {
    // console.log("camera:", camera.position)
    // console.log("controls:", controls.current.target)
    controls.current.update()
    if (props.xlr || props.ts) {
      if (animating) {
        camera.position.x = lerp(camera.position.x, -8.74, speed, delta)
        camera.position.y = lerp(camera.position.y, 2.968, speed, delta)
        camera.position.z = lerp(camera.position.z, -7.27, speed, delta)

        if (props.xlr) {
          controls.current.target.x = lerp(controls.current.target.x, -4.607, speed, delta)
          controls.current.target.y = lerp(controls.current.target.y, 2.461, speed, delta)
          controls.current.target.z = lerp(controls.current.target.z, -8.452, speed, delta)
          controls.current.update()
        }
        if (props.ts) {
          controls.current.target.x = lerp(controls.current.target.x, -4.56, speed, delta)
          controls.current.target.y = lerp(controls.current.target.y, 3.489, speed, delta)
          controls.current.target.z = lerp(controls.current.target.z, -8.41, speed, delta)
          controls.current.update()
        }
      }
    }
    if (!props.xlr && !props.ts) {
      controls.current.target.x = lerp(controls.current.target.x, 0, 0.015, delta)
      controls.current.target.y = lerp(controls.current.target.y, 0, 0.015, delta)
      controls.current.target.z = lerp(controls.current.target.z, 0, 0.015, delta)
      controls.current.update()
    }
  })
  useEffect(() => {
    if (!props.xlr && !props.ts) {
      setAccessory(false)
    }
    if (props.xlr || props.ts) {
      if ((!props.xlr && props.ts) || (props.xlr && !props.ts))
        if (!accessory) {
          // setAccessory(true)
          camera.position.x = -10.411799102866384
          camera.position.y = 3.2625610147696486
          camera.position.z = -2.054937738791965

          controls.current.target.x = -2.815832179366609
          controls.current.target.y = 2.66170399460264
          controls.current.target.z = -3.744213506386723
          controls.current.update()
        }
      setTimeout(function () {
        setAnimating(true)
      }, 650)

      setTimeout(function () {
        setAnimating(false)
      }, 1800)
    }
  }, [props.xlr, props.ts])

  const { scene, animations } = useGLTF(`/s1.glb`)
  const { actions } = useAnimations(animations, group)
  const matgroup = ["black_plastic", "metal_and_buttons", "black_plastic.001", "metal_and_buttons.001", "metal"]

  useEffect(() => {
    scene.traverse(function (child) {
      if (child.material) {
        child.material.dithering = true
        child.material.envMapIntensity = config.envmap[color]
      }
    })
  }, [])

  useEffect(() => {
    actions.xlrEject.play()
    actions.xlrEject.setLoop(LoopOnce)
    actions.xlrEject.clampWhenFinished = true

    actions.tsEject.play()
    actions.tsEject.setLoop(LoopOnce)
    actions.tsEject.clampWhenFinished = true
  }, [])

  useEffect(() => {
    if (props.xlr) {
      actions.xlrEject.paused = false
      actions.xlrEject.timeScale = 1
    }
    if (props.ts) {
      actions.tsEject.paused = false
      actions.tsEject.timeScale = 1
    }
    if (!props.ts) {
      actions.tsEject.play()
      actions.tsEject.paused = false
      actions.tsEject.timeScale = -1
    }
    if (!props.xlr) {
      actions.xlrEject.play()
      actions.xlrEject.paused = false
      actions.xlrEject.timeScale = -1
    }
  }, [props.xlr, props.ts])

  return (
    <>
      <orbitControls
        ref={controls}
        args={[camera, domElement]}
        enableZoom={props.xlr || props.ts ? false : true}
        enableKeys={true}
        enablePan={false}
        enabled={true}
        // target={idle.target}
        rotateSpeed={config.cameraSpeed}
        enableDamping={true}
        dampingFactor={0.05}
        maxDistance={30}
        minDistance={props.xlr || props.ts ? 4.3 : 7.3}
        maxPolarAngle={Math.PI / 2}
      />
      <primitive
        ref={group}
        object={scene}
        dispose={null}
        scale={config.scale}
        position={config.position}
        rotation={config.rotation}
        visible={true}
      />
    </>
  )
}
export default function App() {
  const product = "s1"
  const noZoom = false
  const closeButton = new URLSearchParams(window.location.search).get("closeButton")
  const [color, setColor] = useState(0)
  const { t } = useTranslation()
  const [xlr, setXlr] = useState(false)
  const [ts, setTs] = useState(false)
  const config = ModelConfig[product]
  const [{ reseller, analytics, locale, r, catalina, hide, xlrPlay }, dispatchReseller] = useReseller()
  const [loaded, setLoaded] = useState(false)
  const [idle, setIdle] = useState({ idle: false, target: null })
  const [update, setUpdate] = useState(true)
  const [zoomed, setZoomed] = useState(false)
  const [orbit, setOrbit] = useState(true)
  const controls = useRef()
  const mesh = useRef()
  const canvas = useRef()
  const [keyPressed, setKeyPressed] = useState(false)

  function Loader() {
    const { progress } = useProgress()
    return (
      <Html center style={{ fontSize: "34px", width: "100px", height: "100px" }}>
        <CircularProgressbar
          styles={buildStyles({
            strokeLinecap: "butt",
            textColor: "rgb(36, 36, 36)",
            pathColor: "rgb(36, 36, 36)",
            trailColor: "transparent",
          })}
          value={progress.toFixed(0)}
          text={`${progress.toFixed(0)}%`}
        />
      </Html>
    )
  }

  useEffect(() => {
    window.history.replaceState(null, "", `/${i18n.language}${window.location.search}`)
  }, [i18n.language])

  useEffect(() => {
    if (hide.includes("1")) {
      for (let i = 0; i < colorItems.length; i++) {
        if (!hide.includes(i + 1)) {
          setColor(i)
          break
        }
      }
    }
  }, [hide])

  // function downHandler({ key }) {
  //   if (key === "ArrowLeft" && !keyPressed) {
  //     setUpdate(false)
  //     controls.current.rotateLeft(5)
  //     controls.current.update()
  //     setKeyPressed(() => ({
  //       ...true,
  //     }))
  //   }
  //   if (key === "ArrowUp" && !keyPressed) {
  //     setUpdate(false)
  //     controls.current.rotateUp(5)
  //     controls.current.update()
  //     setKeyPressed(() => ({
  //       ...true,
  //     }))
  //   }
  //   if (key === "-" && !keyPressed) {
  //     setUpdate(false)
  //     controls.current.dollyIn(1.1)
  //     controls.current.update()
  //     setKeyPressed(() => ({
  //       ...true,
  //     }))
  //   }
  //   if (key === "+" && !keyPressed) {
  //     setUpdate(false)
  //     controls.current.dollyOut(1.1)
  //     controls.current.update()
  //     setKeyPressed(() => ({
  //       ...true,
  //     }))
  //   }
  //   if (key === "ArrowDown" && !keyPressed) {
  //     setUpdate(false)
  //     controls.current.rotateUp(-5)
  //     controls.current.update()
  //     setKeyPressed(() => ({
  //       ...true,
  //     }))
  //   }
  //   if (key === "ArrowRight" && !keyPressed) {
  //     setUpdate(false)
  //     controls.current.rotateLeft(-5)
  //     controls.current.update()
  //     setKeyPressed(() => ({
  //       ...true,
  //     }))
  //   }
  // }
  // const upHandler = ({ key }) => {
  //   if (key === "ArrowLeft" || "ArrowRight" || "ArrowDown" || "ArrowUp" || "-" || "+") {
  //     setUpdate(false)
  //     setKeyPressed(() => ({
  //       ...false,
  //     }))
  //   }
  // }

  // const clickHandler = () => {
  //   setUpdate(true)
  // }

  // useEffect(() => {
  //   window.addEventListener("click", clickHandler)
  //   window.addEventListener("keydown", downHandler)
  //   window.addEventListener("keyup", upHandler)
  //   return () => {
  //     window.removeEventListener("keydown", downHandler)
  //     window.removeEventListener("keyup", upHandler)
  //   }
  // }, [])

  const getColorItems = () => {
    return config.colorNames
  }

  const colorList = getColorItems().map((currElement, index) => (
    <div
      key={index}
      tabIndex={0}
      onClick={() => {
        setColor(index)
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setColor(index)
        }
      }}
      className={"colorOuter"}
      style={{
        display: hide.includes(index + 1) ? "none" : "block",
        border: color == index ? "solid 1px rgb(41, 41, 41)" : "solid 1px transparent",
      }}>
      <div style={{ backgroundColor: config.colorValues[index] }} className={"colorInner"}></div>
    </div>
  ))
  const getDpr = () => {
    if (!isMobile) {
      return catalina ? 1 : 2
    } else return 3
  }

  const renderHandOverlay = () => {
    if (loaded) {
      return (
        <div className={"handInstruction"} style={{ opacity: loaded ? "1" : "none" }}>
          <svg viewBox="0 0 25 36" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-11 -4)" fill="none" fillRule="evenodd">
              <path d="M0 0h44v44H0z" fill="#fff" fillOpacity="0" />
              <g transform="translate(11 3)">
                <path
                  d="M8.733 11.165c.04-1.108.766-2.027 1.743-2.307a2.54 2.54 0 0 1 .628-.089c.16 0 .314.017.463.044 1.088.2 1.9 1.092 1.9 2.16v8.88h1.26c2.943-1.39 5-4.45 5-8.025a9.01 9.01 0 0 0-1.9-5.56l-.43-.5c-.765-.838-1.683-1.522-2.712-2-1.057-.49-2.226-.77-3.46-.77s-2.4.278-3.46.77c-1.03.478-1.947 1.162-2.71 2l-.43.5a9.01 9.01 0 0 0-1.9 5.56 9.04 9.04 0 0 0 .094 1.305c.03.21.088.41.13.617l.136.624c.083.286.196.56.305.832l.124.333a8.78 8.78 0 0 0 .509.953l.065.122a8.69 8.69 0 0 0 3.521 3.191l1.11.537v-9.178z"
                  fill="#e4e4e4"
                  fillOpacity=".5"
                />
                <path
                  d="M22.94 26.218l-2.76 7.74c-.172.485-.676.8-1.253.8H12.24c-1.606 0-3.092-.68-3.98-1.82-1.592-2.048-3.647-3.822-6.11-5.27-.095-.055-.15-.137-.152-.23-.004-.1.046-.196.193-.297.56-.393 1.234-.6 1.926-.6a3.43 3.43 0 0 1 .691.069l4.922.994V10.972c0-.663.615-1.203 1.37-1.203s1.373.54 1.373 1.203v9.882h2.953c.273 0 .533.073.757.21l6.257 3.874c.027.017.045.042.07.06.41.296.586.77.426 1.22M4.1 16.614c-.024-.04-.042-.083-.065-.122a8.69 8.69 0 0 1-.509-.953c-.048-.107-.08-.223-.124-.333l-.305-.832c-.058-.202-.09-.416-.136-.624l-.13-.617a9.03 9.03 0 0 1-.094-1.305c0-2.107.714-4.04 1.9-5.56l.43-.5c.764-.84 1.682-1.523 2.71-2 1.058-.49 2.226-.77 3.46-.77s2.402.28 3.46.77c1.03.477 1.947 1.16 2.712 2l.428.5a9 9 0 0 1 1.901 5.559c0 3.577-2.056 6.636-5 8.026h-1.26v-8.882c0-1.067-.822-1.96-1.9-2.16-.15-.028-.304-.044-.463-.044-.22 0-.427.037-.628.09-.977.28-1.703 1.198-1.743 2.306v9.178l-1.11-.537C6.18 19.098 4.96 18 4.1 16.614M22.97 24.09l-6.256-3.874c-.102-.063-.218-.098-.33-.144 2.683-1.8 4.354-4.855 4.354-8.243 0-.486-.037-.964-.104-1.43a9.97 9.97 0 0 0-1.57-4.128l-.295-.408-.066-.092a10.05 10.05 0 0 0-.949-1.078c-.342-.334-.708-.643-1.094-.922-1.155-.834-2.492-1.412-3.94-1.65l-.732-.088-.748-.03a9.29 9.29 0 0 0-1.482.119c-1.447.238-2.786.816-3.94 1.65a9.33 9.33 0 0 0-.813.686 9.59 9.59 0 0 0-.845.877l-.385.437-.36.5-.288.468-.418.778-.04.09c-.593 1.28-.93 2.71-.93 4.222 0 3.832 2.182 7.342 5.56 8.938l1.437.68v4.946L5 25.64a4.44 4.44 0 0 0-.888-.086c-.017 0-.034.003-.05.003-.252.004-.503.033-.75.08a5.08 5.08 0 0 0-.237.056c-.193.046-.382.107-.568.18-.075.03-.15.057-.225.1-.25.114-.494.244-.723.405a1.31 1.31 0 0 0-.566 1.122 1.28 1.28 0 0 0 .645 1.051C4 29.925 5.96 31.614 7.473 33.563a5.06 5.06 0 0 0 .434.491c1.086 1.082 2.656 1.713 4.326 1.715h6.697c.748-.001 1.43-.333 1.858-.872.142-.18.256-.38.336-.602l2.757-7.74c.094-.26.13-.53.112-.794s-.088-.52-.203-.76a2.19 2.19 0 0 0-.821-.91"
                  fill="#000"
                  fillOpacity=".6"
                />
                <path
                  d="M22.444 24.94l-6.257-3.874a1.45 1.45 0 0 0-.757-.211h-2.953v-9.88c0-.663-.616-1.203-1.373-1.203s-1.37.54-1.37 1.203v16.643l-4.922-.994a3.44 3.44 0 0 0-.692-.069 3.35 3.35 0 0 0-1.925.598c-.147.102-.198.198-.194.298.004.094.058.176.153.23 2.462 1.448 4.517 3.22 6.11 5.27.887 1.14 2.373 1.82 3.98 1.82h6.686c.577 0 1.08-.326 1.253-.8l2.76-7.74c.16-.448-.017-.923-.426-1.22-.025-.02-.043-.043-.07-.06z"
                  fill="#fff"
                />
                <g transform="translate(0 .769)">
                  <mask id="a" fill="#fff" />
                  <path
                    d="M23.993 24.992a1.96 1.96 0 0 1-.111.794l-2.758 7.74c-.08.22-.194.423-.336.602-.427.54-1.11.87-1.857.872h-6.698c-1.67-.002-3.24-.633-4.326-1.715-.154-.154-.3-.318-.434-.49C5.96 30.846 4 29.157 1.646 27.773c-.385-.225-.626-.618-.645-1.05a1.31 1.31 0 0 1 .566-1.122 4.56 4.56 0 0 1 .723-.405l.225-.1a4.3 4.3 0 0 1 .568-.18l.237-.056c.248-.046.5-.075.75-.08.018 0 .034-.003.05-.003.303-.001.597.027.89.086l3.722.752V20.68l-1.436-.68c-3.377-1.596-5.56-5.106-5.56-8.938 0-1.51.336-2.94.93-4.222.015-.03.025-.06.04-.09.127-.267.268-.525.418-.778.093-.16.186-.316.288-.468.063-.095.133-.186.2-.277L3.773 5c.118-.155.26-.29.385-.437.266-.3.544-.604.845-.877a9.33 9.33 0 0 1 .813-.686C6.97 2.167 8.31 1.59 9.757 1.35a9.27 9.27 0 0 1 1.481-.119 8.82 8.82 0 0 1 .748.031c.247.02.49.05.733.088 1.448.238 2.786.816 3.94 1.65.387.28.752.588 1.094.922a9.94 9.94 0 0 1 .949 1.078l.066.092c.102.133.203.268.295.408a9.97 9.97 0 0 1 1.571 4.128c.066.467.103.945.103 1.43 0 3.388-1.67 6.453-4.353 8.243.11.046.227.08.33.144l6.256 3.874c.37.23.645.55.82.9.115.24.185.498.203.76m.697-1.195c-.265-.55-.677-1.007-1.194-1.326l-5.323-3.297c2.255-2.037 3.564-4.97 3.564-8.114 0-2.19-.637-4.304-1.84-6.114-.126-.188-.26-.37-.4-.552-.645-.848-1.402-1.6-2.252-2.204C15.472.91 13.393.232 11.238.232A10.21 10.21 0 0 0 5.23 2.19c-.848.614-1.606 1.356-2.253 2.205-.136.18-.272.363-.398.55C1.374 6.756.737 8.87.737 11.06c0 4.218 2.407 8.08 6.133 9.842l.863.41v3.092l-2.525-.51c-.356-.07-.717-.106-1.076-.106a5.45 5.45 0 0 0-3.14.996c-.653.46-1.022 1.202-.99 1.983a2.28 2.28 0 0 0 1.138 1.872c2.24 1.318 4.106 2.923 5.543 4.772 1.26 1.62 3.333 2.59 5.55 2.592h6.698c1.42-.001 2.68-.86 3.134-2.138l2.76-7.74c.272-.757.224-1.584-.134-2.325"
                    fill="#000"
                    mask="url(#a)"
                    fillOpacity=".05"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      )
    }
  }
  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale)
    }
  }, [analytics, locale, r])

  const [currentLocale, setCurrentLocale] = useState("en-US")
  const localeLang = `${currentLocale}`

  useEffect(() => {
    setCurrentLocale(`${window.location.pathname}`)
  }, [])

  const getFontFamily = (fontLocale) => {
    switch (fontLocale) {
      case "/ja-JP":
        return `BoseText-JP`
      case "/zh-HK":
        return `BoseText-ZH`
      case "/zh-TW":
        return `BoseText-ZH`
      default:
        return `BoseText`
    }
  }

  return (
    <>
      <div className={"container"} style={{ fontFamily: getFontFamily(localeLang) }}>
        {renderHandOverlay()}
        <div
          style={{ display: closeButton == "true" ? "block" : "none" }}
          className={"closeButton"}
          onClick={() => {
            if (window !== window.parent) {
              window.parent.postMessage("360_close", "*")
            } else {
              window.location.href =
                "http://www.bose.com/en_us/products/speakers/home_theater/bose-smart-soundbar-900.html#v=bose_smart_soundbar_900_white"
            }
          }}>
          <img className={"closeIcon"} src="/close.svg" />
        </div>
        <div className="blocker"></div>
        <div className={"colorPicker"} style={{ bottom: reseller ? "30px" : "5px" }}>
          <p style={{ display: xlr || ts ? "block" : "none" }} className="note">
            {`${t("s1Copy.seperately")}`}
          </p>
          <div className={"optionWrapper"}>
            <h3
              onClick={() => {
                setTs(false)
                setXlr(false)
              }}
              className={"mainOption"}>
              {`${t("s1Copy.product")}`} <strong>{t("s1Copy.productName")}</strong>
              {/* {`${t("general.color")}:`} <strong>{t(`${product}.colors.${color}`)}</strong> */}
              {/* <div className={"colorOptions"}>{colorList}</div> */}
            </h3>
            <h3
              className="xlr"
              style={{
                margin: 0,
                opacity: xlr ? 1 : 0.5,
                marginRight: "8px",
                paddingTop: "15px",
                width: "33%",
              }}
              onClick={() => {
                if (!xlr) {
                  setXlr(true)
                  setTs(false)
                }
                if (xlr) {
                  setXlr(false)
                }
              }}>
              {t("s1Copy.accessory")} <strong>{t("s1Copy.a1Name")}</strong>
            </h3>
            <h3
              className="ts"
              style={{
                margin: 0,
                opacity: ts ? 1 : 0.5,
                paddingTop: "15px",
                marginRight: "8px",
                width: "33%",
              }}
              onClick={() => {
                if (!ts) {
                  setTs(true)
                  setXlr(false)
                }
                if (ts) {
                  setTs(false)
                }
              }}>
              {t("s1Copy.accessory")} <strong>{t("s1Copy.a2Name")}</strong>
            </h3>
          </div>
        </div>
        <PrivacyOverlay />
        <LanguageOverlay />

        <Canvas
          ref={canvas}
          gl={{ antialias: true, alpha: true }}
          dpr={getDpr()}
          camera={{ fov: 70, position: isMobile ? config.cameraPositionMobile : config.cameraPositionDesktop }}
          onCreated={({ gl }) => gl.setClearColor("rgb(235, 235, 235)")}>
          <ambientLight intensity={config.ambient[color]} />
          <Suspense fallback={<Loader />}>
            {/* <mesh ref={mesh}>
              <boxGeometry args={[9, 12, 9]} />
              <meshStandardMaterial color={"white"} />
            </mesh> */}
            <LoadedModel xlr={xlr} ts={ts} controls={controls} />
            <Environment files={config.hdri} />
          </Suspense>
          <EffectComposer multisampling={12}>
            <Bloom luminanceThreshold={config.bloomValue[color]} luminanceSmoothing={0.65} height={600} width={400} />

            {/* <Noise opacity={0.025} /> */}
            <SSAO samples={15} intensity={config.ssaoIntensity[color]} radius={2} luminanceInfluence={0.5} bias={0.025} />
            <ToneMapping
              adaptive={false}
              middleGrey={config.middleGrey}
              maxLuminance={config.maxLuminance}
              averageLuminance={config.averageLuminance}
            />
          </EffectComposer>
        </Canvas>
        <div style={{ position: "relative", width: "100%" }}>{<ResellerBar />}</div>
      </div>
    </>
  )
}
