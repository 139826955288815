import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useRouter from "../hooks/useRouter"
import i18n from "i18next"
import { useReseller } from "../context/resellerContext"
import { languages } from "../data/languages"

const LanguageOverlay = () => {
  const [{ languageSelector, privacyPolicy, showLanguageSelector }, dispatchReseller] = useReseller()
  const router = useRouter()
  // const { query, pathname } = useRouter()
  const { t } = useTranslation()
  const getFlagUrl = () =>
    `https://assets.bose.com/content/dam/Bose_DAM/Web/consumer_electronics/bose_global_portal/flags/${t(
      "composer.countryName",
    )}.png/_jcr_content/renditions/cq5dam.web.1280.1280.png`

  const handleLangButton = () => {
    if (languageSelector) {
      dispatchReseller({ type: "showLanguageSelector" })
    } else {
      dispatchReseller({ type: "showLanguageSelector" })
    }
  }
  console.log
  useEffect(() => {
    if (languageSelector || privacyPolicy) {
      const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      const modal = languageSelector ? document.querySelector(".langOverlay") : document.querySelector(".privacyOverlay")
      const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]

      const focusableContent = modal.querySelectorAll(focusableElements)
      const lastFocusableElement = focusableContent[focusableContent.length - 1]
      firstFocusableElement.focus()
      document.addEventListener("keydown", (e) => {
        const isTabPressed = e.key === "Tab"
        if (!isTabPressed) {
          return
        }
        if (!modal.contains(document.activeElement)) {
          firstFocusableElement.focus()
        }
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus()
            e.preventDefault()
          }
        } else if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus()
          e.preventDefault()
        }
      })
    }
  }, [languageSelector, privacyPolicy])
  const searchLanguages = (reset) => {
    let item
    let i
    let txtValue
    const input = document.getElementById("searchInput")
    const filter = input.value.toUpperCase()
    const ul = document.getElementById("languageList")
    const li = ul.getElementsByTagName("li")

    // eslint-disable-next-line no-plusplus
    for (i = 0; i < li.length; i++) {
      // eslint-disable-next-line prefer-destructuring
      item = li[i].getElementsByClassName("listLang")[0]
      txtValue = item.textContent + li[i].className
      if (reset) {
        li[i].style.display = ""
      }
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = ""
      } else if (!reset) {
        li[i].style.display = "none"
      }
    }
  }

  return (
    <div aria-modal="true" className={`langOverlay ${showLanguageSelector ? "langOverlay-shown" : "langOverlay-hidden"}`}>
      <div className="languages">
        <div className="activeWrapper">
          <div
            role="button"
            className="langOverlayHide"
            tabIndex={0}
            onClick={() => {
              handleLangButton()
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLangButton()
              }
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 5.014 7.476"
              style={{
                stroke: "black",
                strokeWidth: "0.5",
              }}>
              <defs />
              <path d="M.334,6.1,0,5.784,2.895,3.05,0,.316.334,0,3.558,3.05Z" transform="translate(4.286 6.788) rotate(180)" />
            </svg>
          </div>
          <div className="activeLanguage">
            {t("composer.langName")} ({t("composer.countryISO")}
            )
            <img src={getFlagUrl()} alt="flag icon" />
          </div>
          <div className="searchBar">
            <form>
              <input
                tabIndex={0}
                type="text"
                id="searchInput"
                onKeyUp={() => {
                  searchLanguages(false)
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                  }
                }}
                placeholder={String.fromCodePoint(61442) + t("composer.searchCopy")}
              />
              <span>
                <input
                  tabIndex={0}
                  type="reset"
                  value="&#xf00d;"
                  onClick={() => {
                    searchLanguages(true)
                  }}
                />
              </span>
            </form>
          </div>
        </div>
        <ul id="languageList">
          {languages.map((d, idx) => (
            // these are static sets, https://github.com/yannickcr/eslint-plugin-react/issues/1123
            // eslint-disable-next-line react/no-array-index-key
            <li className={d.meta} key={idx}>
              <div
                className="listLang"
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    i18n.changeLanguage(d.lng)
                    handleLangButton()
                  }
                }}
                onClick={() => {
                  i18n.changeLanguage(d.lng)
                  handleLangButton()
                }}>
                <span>
                  {d.language}({d.iso})
                </span>
                <img
                  src={`https://assets.bose.com/content/dam/Bose_DAM/Web/consumer_electronics/bose_global_portal/flags/${d.country}.png/_jcr_content/renditions/cq5dam.web.1280.1280.png`}
                  alt="flag icon"
                />
              </div>
            </li>
          ))}
        </ul>
        <button
          type="button"
          tabIndex={0}
          onClick={() => {
            handleLangButton()
          }}>
          {t("composer.closeButton")}
        </button>
      </div>
    </div>
  )
}
export default LanguageOverlay
