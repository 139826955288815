import React, { Suspense, StrictMode } from "react"
import ReactDOM from "react-dom"
import "./styles.scss"
import App from "./App"
import { ResellerProvider } from "./context/resellerContext"

ReactDOM.render(
  <ResellerProvider>
    <StrictMode>
      <App />
    </StrictMode>
  </ResellerProvider>,
  document.getElementById("root"),
)
