/* eslint-disable no-param-reassign */
import { useEffect, useState } from "react"
import axios from "axios"
import ReactHtmlParser from "react-html-parser"
import { useTranslation } from "react-i18next"
import { useReseller } from "../context/resellerContext"

const PrivacyOverlay = () => {
  const [privacyPolicyHtml, setPrivacyPolicyHtml] = useState("")
  const [{ showPrivacyPolicy }, dispatchReseller] = useReseller()
  const { t } = useTranslation()
  const contains = (selector, context, exp) => {
    const elements = context.querySelectorAll(selector)
    return Array.prototype.filter.call(elements, (element) => RegExp(exp).test(element))
  }
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!showPrivacyPolicy) return ""
    ;(async () => {
      const parser = new DOMParser()
      const privacyRequest = await axios.get(`/api/cors?uri=${t("composer.legalLink")}`)
      const privacyData = await privacyRequest.data
      const parseDoc = parser.parseFromString(privacyData, "text/html")
      const anchors = contains("a", parseDoc, "/legal|contact")
      const contacts = contains("a", parseDoc, "/email-protection")
      anchors.forEach((a) => {
        a.href = t("composer.domainPrefix") + a.pathname
        a.target = "_blank"
      })
      contacts.forEach((a) => {
        a.href = "mailto:privacyandsecurity@bose.com"
        a.innerHTML = "privacyandsecurity@bose.com"
      })
      const html = parseDoc.getElementsByClassName("column")[0].outerHTML
      return setPrivacyPolicyHtml(html)
    })()
  }, [t, showPrivacyPolicy])

  const setPrivacy = () => {
    dispatchReseller({ type: "showPrivacyPolicy" })
  }

  return (
    <div aria-modal="true" className={`privacyOverlay ${showPrivacyPolicy ? "privacyOverlay-shown" : "privacyOverlay-hidden"}`}>
      <div className="policy">
        <div
          role="button"
          className="privacyHide"
          tabIndex={0}
          onClick={() => {
            // sendAnalyticsCall({
            //   buttonType: "soudbardemo-click",
            //   buttonName: "soudbardemo-privacy-overlay-close",
            //   analytics: audio.analytics,
            // });
            setPrivacy()
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // sendAnalyticsCall({
              //   buttonType: "soudbardemo-click",
              //   buttonName: "soudbardemo-privacy-overlay-close",
              //   analytics: audio.analytics,
              // });
              setPrivacy()
            }
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 5.014 7.476"
            style={{
              stroke: "black",
              strokeWidth: "0.5",
            }}>
            <defs />
            <path d="M.334,6.1,0,5.784,2.895,3.05,0,.316.334,0,3.558,3.05Z" transform="translate(4.286 6.788) rotate(180)" />
          </svg>
        </div>
        {ReactHtmlParser(privacyPolicyHtml)}
      </div>
      <button
        type="button"
        tabIndex={0}
        onClick={() => {
          // sendAnalyticsCall({
          //   buttonType: "soudbardemo-click",
          //   buttonName: "soudbardemo-privacy-overlay-close",
          //   analytics: audio.analytics,
          // });
          setPrivacy()
        }}>
        {t("composer.closeButton")}
      </button>
    </div>
  )
}
export default PrivacyOverlay
